import styled from '@emotion/styled';
import { Col, Row, Layout, Menu } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import PersonIcon from '@material-ui/icons/Person';
import ForumIcon from '@material-ui/icons/Forum';

import { Link16 } from './link';

import { Button } from '../../components';
import Input from '../../components/forms/input';
import { LAYOUT_CONFIG } from '../../constants';
import theme from '../../theme';
const { MAX_PAGE_WIDTH } = LAYOUT_CONFIG;

export const DefaultUpIcon = styled(UpOutlined)`
  font-size: 16px;
`;
export const DefaultDownIcon = styled(DownOutlined)`
  font-size: 16px;
`;
export const HeaderImageContainer = styled.img`
  height: 100%;
  cursor: pointer;
`;

export const HeaderTextContainer = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 3.33;
  display: inline;
  margin-right: 0;
  cursor: pointer;
  padding-right: 0;
`;

export const UserIcon = styled(PersonIcon)`
  color: ${theme.colorsPrimaryBlue};
  width: 19px !important;
  height: 19px !important;
  margin-bottom: -8px;
`;

export const MessageIcon = styled(ForumIcon)`
  color: ${theme.colorsPrimaryBlue};
  width: 18px !important;
  height: 18px !important;
  margin-bottom: -8px;
`;

export const IconsWrapper = styled.div`
  display: inline-block;
  position: relative;
  top: -3px;
`;

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: inline-block;
  border: 1px solid ${theme.colorsLightGreyOutline};
  border-radius: 2px;
  margin-right: 16px;
  cursor: pointer;
  text-align: center;
`;
export const GreetingTextContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  display: inline;
  margin-right: 16px;
  color: ${theme.colorsBlack};
`;

export const HeaderContainer = styled(Layout)`
  @media print {
    * {
      display: none;
    }
  }
  width: 100%;
  background-color: ${theme.colorsWhite};
  box-shadow: inset 0 -1px 0 0 ${theme.colorsHr};

  padding-top: env(safe-area-inset-top);

  &.is-admin {
    min-width: ${MAX_PAGE_WIDTH}px;
  }

  @media (max-width: ${theme.screenMdMax}) {
    box-shadow: none;
  }
`;

export const PublicHeaderContainer = styled(HeaderContainer)`
  @media (max-width: ${theme.screenMdMax}) {
    padding: 0 20px;
    padding-top: env(safe-area-inset-top);
  }
`;

export const HeaderSection = styled(Row)`
  width: 100%;
  max-width: ${theme.screenLgMax};
  height: 70px;
  margin: 0 auto;

  @media (max-width: ${theme.screenXl}) {
    padding: 0 24px;
    &.is-admin {
      padding: 0;
    }
  }
`;

export const TopHeaderSection = styled(HeaderSection)`
  border-bottom: 1px solid #eee;
  height: 52px;

  @media (max-width: ${theme.screenMdMax}) {
    display: none;
  }
`;

export const ImageWrapper = styled(Col)`
  vertical-align: middle;
  height: 46px;
  padding-left: 0;
`;

export const SearchWrapper = styled(Col)`
  vertical-align: middle;
  padding-left: 0;
`;

export const LeftContainer = styled(Col)`
  padding-top: 4px;
  padding-left: 0;
  margin-right: 32px;
  cursor: pointer;
`;

export const RightContainer = styled(Col)`
  text-align: right;
  padding-right: 0;
`;

export const ArrowIcon = styled.span`
  margin-left: 5px;
`;

export const StyledMenu = styled(Menu)`
  && {
    font-size: 16px;
    color: ${theme.colorsBlack};
    font-weight: bold;
    border: none;
    width: 695px;
  }
  & > .ant-menu-submenu:hover,
  & > .ant-menu-submenu-active,
  & > .ant-menu-submenu-active .ant-menu-submenu-title,
  & .ant-menu-submenu-title:hover {
    color: ${theme.colorsSecondaryBlue} !important;
    border-color: ${theme.colorsSecondaryBlue} !important;
  }
`;

export const SubMenuItem = styled(Menu.Item)`
  && {
    font-size: 16px;
    color: ${theme.colorsBlack};
    width: 216px;
    height: 45px;
    padding-top: 4px;
    margin-bottom: 0;
    margin-top: 0;
    :not(:last-child) {
      margin-bottom: 0;
    }
    :hover {
      background-color: ${theme.colorsPrimaryBlue};
      color: ${theme.colorsWhite};
    }
  }
`;

export const ButtonMenuItem = styled(Menu.Item)`
  && {
    border: 0;
  }
  &&:hover {
    border: 0;
  }
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    margin: 0 8px;
    max-width: 100%;
    width: auto;
    && {
      font-size: 14px;
    }
  }
`;

export const HeaderSearch = styled(Input)`
  padding: 0 !important;
  border: 0 !important;
  &&& {
    svg {
      color: ${theme.colorsBlack};
    }
    input {
      border: 0;
    }
  }
`;

export const DropdownItem = styled.button`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  border: 0;
  color: ${theme.colorsBlack};
  background-color: ${theme.colorsWhite};
  &:focus {
    border: 0;
    outline: 0;
  }
  svg {
    overflow: hidden;
    font-size: 16px;
    vertical-align: sub;
  }
`;

export const DashLink = styled(Link16)`
  color: ${theme.colorsBlack};
  text-decoration: none;
  padding-right: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;

  svg {
    position: absolute;
    right: 0;
    & + span {
      padding-left: 24px;
    }
  }

  &:visited {
    color: ${theme.colorsBlack};

    svg {
      color: ${theme.colorsPrimaryBlue};
    }
  }
`;

export const AuthLink = styled(Link16)`
  color: ${theme.colorsBlack};
  text-decoration: none;
  margin-right: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;

  svg {
    position: absolute;
    left: 0;
    & + span {
      padding-left: 24px;
    }
  }

  &:visited {
    color: ${theme.colorsBlack};
    svg {
      color: ${theme.colorsPrimaryBlue};
    }
  }
`;

export const UserIdTextContainer = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-align: left !important;
  color: ${theme.colorsLightGreyOutline};
`;

export const MobileUserIdTextContainer = styled(UserIdTextContainer)`
  position: fixed;
  right: 24px;
`;

export const UserDataWrapper = styled.div`
  vertical-align: middle;
  margin: 0 10px;
  display: inline-block;
`;
